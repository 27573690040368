import store from "@/store";
import { getEquipment, getEquipmentIndicators, getEquipmentSensor } from "../../services/thermometry";

const equipmentState = {
    id: null,
    name: null,
    alert_id: null,
    doorStatus: null,
    location: null,
    family: null,
    temperature: null,
    sensorPositions: [],
    max_temperature: null,
    min_temperature: null,
    nominalValue: null,
    mean_temperature: null,
    userId: null,
    alertDate: null,
    alertType: null,
    alertTime: null,
    mails: null,
    phones: null,
    sensor: {
        id: null,
        type: null,
        registerRange: null,
        registerRangeSend: null,
        battery: null,
        identifier: null
    },
    indicators: {
        alertsCount: null,
        doorOpenCount: null,
        duration: null
    },
    alert: {
        id: null,
        date: null,
        max_temperature: null,
        min_temperature: null,
        value_at_start_date: null
    }
};

const equipmentModule = {
    namespaced: true,
    state: equipmentState,
    getters: {},
    actions: {
        initStore({ commit }, {institutionId, equipmentId, selectedPeriod}) {
            commit("resetEquipmentState");

            store.dispatch("equipmentDetails/refreshEquipmentInfo", {institutionId, equipmentId});
            store.dispatch("equipmentDetails/refreshEquipmentIndicators", {institutionId, equipmentId, selectedPeriod});
            store.dispatch("equipmentDetails/refreshEquipmentSensor", {institutionId, equipmentId});
        },
        refreshEquipmentInfo({ commit }, {institutionId, equipmentId}) {
            getEquipment(institutionId, equipmentId, (response) => {
                commit("updateEquipmentInfo", response.data);
            }, (error) => {
                console.error(error)
            });
        },
        refreshEquipmentIndicators({ commit }, {institutionId, equipmentId, selectedPeriod}) {
            getEquipmentIndicators(institutionId, equipmentId, selectedPeriod, (response) => {
                commit("updateEquipmentIndicators", response.data);
            }, (error) => {
                console.error(error)
            });
        },
        refreshEquipmentSensor({ commit }, {institutionId, equipmentId}) {
            getEquipmentSensor(institutionId, equipmentId, (response) => {
                commit("updateEquipmentSensor", response.data);
            }, (error) => {
                console.error(error)
            });
        },
    },
    mutations: {
        resetEquipmentState(state) {
            state.id = null;
            state.name = null;
            state.alert_id = null;
            state.location = null;
            state.doorStatus = null;
            state.family = null;
            state.temperature = null;
            state.max_temperature = null;
            state.min_temperature = null;
            state.nominalValue = null;
            state.mean_temperature = null;
            state.userId = null;
            state.alertDate = null;
            state.alertType = null;
            state.sensor.id = null;
            state.sensor.type = null;
            state.sensor.registerRange = null;
            state.sensor.registerRangeSend = null;
            state.sensor.battery = null;
            state.sensor.identifier = null;
            state.indicators.alertsCount = null;
            state.indicators.doorOpenCount = null;
            state.indicators.duration = null;
            state.alertTime = null;
            state.mails = null;
            state.phones = null;
            state.alert = null;
            state.sensorPositions = [];
        },
        updateEquipmentInfo(state, data) {
            state.id = data.id;
            state.name = data.name;
            state.alert_id = data.alert_id;
            state.location = data.location;
            state.doorStatus = data.doorStatus;
            state.family = data.family;
            state.temperature = data.temperature;
            state.max_temperature = data.max_temperature;
            state.min_temperature = data.min_temperature;
            state.nominalValue = data.nominalValue;
            state.userId = data.userId;
            state.alertDate = data.alertDate;
            state.alertType = data.alertType;
            state.alertTime = data.alertTime;
            state.mails = data.mails;
            state.phones = data.phones;
            state.alert = data.alert;
            state.sensorPositions = data.sensorPositions;
        },
        updateEquipmentIndicators(state, data) {
            state.indicators.alertsCount = data.alertsCount;
            state.indicators.doorOpenCount = data.doorOpenCount;
            state.indicators.duration = data.duration;
        },
        updateEquipmentSensor(state, data) {
            state.sensor.id = data.id;
            state.sensor.type = data.type;
            state.sensor.registerRange = data.registerRange;
            state.sensor.registerRangeSend = data.registerRangeSend;
            state.sensor.battery = data.battery;
            state.sensor.identifier = data.identifier;
        },
        updateMeanTemperature(state, data) {
            state.mean_temperature = data;
        }
    },
    modules: {},
};

export default equipmentModule;